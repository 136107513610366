<template>
    <div class="modal">
        <div class="modal-content">
            <h3 class="title">
                لقد تم استخدام هذا البريد الالكتروني بالفعل
            </h3>
            <div class="text">
                هل تريد الوصول إلى شارتك؟
            </div>
            <div class="buttons">
                <button class="no" @click.self="hideModal">كلا</button>
                <button class="yes" @click.self="goToBadge">نعم</button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['email'],
    data() {
        return {
            token_key: ''
        }
    },
    methods: {
        hideModal() {
            this.$emit('hide')
        },
        goToBadge() {
            this.axios.post(this.$store.state.baseURL + 'get-visitor-by-email',
                {
                    'email': this.email
                }).then(response => {
                    if (!response.data.success) {
                        return swal('Error!', response.data.msg, 'error').then(() => {
                            this.qr_mounted = true;
                        })
                    } else {
                        this.$router.push({ path: '/badge', query: { t: response.data.data.token_key } })
                    }
                });
        }
    }
}
</script>

<style scoped>
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #00000040;
    direction: rtl;
}

.modal-content {
    background-color: #fff;
    margin: 20% auto;
    padding: 20px;
    width: 40%;
}

.buttons {
    margin-top: 20px;
    width: fit-content;
    display: block;
    margin-right: auto;
}

.buttons button {
    width: 50px;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 0;
    cursor: pointer;
    color: #FFF;
}

.yes {
    background-color: #178285;
    margin-right: 15px;
}

.no {
    background-color: #F7941D;
}
</style>

<template>
    <ForgotModal v-if="showModal" @hide="hideModal" :email="email"></ForgotModal>
    <div class="registration-view">
        <hr>
        <img src="../assets/logo.png" alt="Logo">
        <h2>من فضلك قم بتعبئة المعلومات الأساسية للحصول على البطاقة الرقمية.</h2>
        <form>
            <div>
                <input name="first_name" :class="firstNameError ? 'error' : ''" type="text" placeholder="الاسم الاول"
                    v-model="firstName">
                <span :class="firstNameError ? 'error' : ''">هذه الخانة مطلوبة</span>
            </div>
            <div>
                <input name="last_name" :class="lastNameError ? 'error' : ''" type="text" placeholder="الاسم الاخير"
                    v-model="lastName">
                <span :class="lastNameError ? 'error' : ''">هذه الخانة مطلوبة</span>
            </div>
            <div>
                <input name="email" :class="emailError ? 'error' : ''" type="email"
                    placeholder="البريد الاكتروني الخاص بالعمل" v-model="email">
                <span :class="emailError ? 'error' : ''">هذه الخانة مطلوبة</span>
            </div>
            <div>
                <input name="phone" :class="phoneError ? 'error' : ''" type="phone" placeholder="رقم الهاتف"
                    v-model="phone">
                <span :class="phoneError ? 'error' : ''">هذه الخانة مطلوبة</span>
            </div>
        </form>
        <button type="submit" @click="register" :disabled="sending">تسجيل</button>

        <!-- <div class="forgot">
            في حالة أنك قمت بالتسجيل بالفعل ولم تحصل على شارتك، <a @click="openModal">انقر هنا</a>
        </div> -->
    </div>
</template>

<script>
import ForgotModal from '@/components/ForgotModal.vue';
import { useToast } from 'vue-toast-notification';

export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            phoneError: false,
            sending: false,
            showModal: false
        }
    },
    components: {
        ForgotModal
    },
    methods: {
        hideModal() {
            this.showModal = !this.showModal;
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.phone = '';
        },
        register() {
            if (this.firstName.split('').length == 0) {
                this.firstNameError = true;
            } else {
                this.firstNameError = false;
            }
            if (this.lastName.split('').length == 0) {
                this.lastNameError = true;
            } else {
                this.lastNameError = false;
            }
            if (this.email.split('').length == 0) {
                this.emailError = true;
            } else {
                this.emailError = false;
            }
            if (this.phone == '') {
                this.phoneError = true;
            } else {
                this.phoneError = false;
            }

            if (this.firstNameError || this.lastNameError || this.emailError || this.phoneError) {
                return;
            }

            this.axios.post(this.$store.state.baseURL + 'register',
                {
                    'first_name': this.firstName,
                    'last_name': this.lastName,
                    'email': this.email,
                    'phone': this.phone
                })
                .then(response => {
                    if (!response.data.success) {
                        if (response.data.error.email == 'The email has already been taken.') {
                            // useToast().error('البريد الإلكتروني مُستخدم بالفعل');
                            this.showModal = true;
                        } else {
                            useToast().error('Something Went Wrong');
                        }
                    } else {
                        this.sending = true
                        this.$router.push({ path: '/badge', query: { t: response.data.data.token_key } })
                    }
                });
        }
    }
}
</script>

<style scoped>
.registration-view {
    text-align: center !important;
    direction: rtl;
}

hr {
    height: 20px;
    margin: 0;
    background-color: #178285;
    border: none;
    outline: none;
}

img {
    width: 350px;
    margin: 50px auto;
}

h2 {
    color: #178285;
    margin: 0 0 50px 0;
    font-size: 20px;
    font-weight: bold;
}

form {
    margin: 0 100px 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}

input {
    height: 40px;
    width: -webkit-fill-available;
    outline: none;
    border: 1px solid #178285;
    border-radius: 0;
    padding-right: 20px;
    font-size: 16px;
}

input.error {
    border: 1px solid #f62525;
}

input:focus {
    border: 1px solid #F7941D;
}

form span {
    color: #f62525;
    display: none;
    margin: 5px 20px 0 auto;
    width: fit-content;
}

form span.error {
    display: block;
}

button {
    width: 200px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #F7941D;
    outline: none;
    background-color: #F7941D;
    color: #FFF;
    transition: all 0.3s ease;
    font-size: 16px;
    margin-bottom: 40px;
}

button:hover {
    background-color: #FFF;
    color: #F7941D;
}

.forgot {
    margin: 40px 0;
}

.forgot a {
    color: #F7941D;
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 768px) {
    form {
        margin: 0 50px 50px;
    }
}

@media (max-width: 555px) {
    form {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
}

@media (max-width: 425px) {
    form {
        margin: 0 25px 30px;
    }

    button {
        margin-bottom: 30px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
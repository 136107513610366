<template>
    <div v-if="loading" class="loading">
        <img class="logo" src="../assets/loader.png" alt="Loader">
    </div>
    <div v-else>
        <div class="html-content">
            <img class="logo" src="../assets/logo.png" alt="Logo">

            <img class="qrcode" :src="'https://hr-registration.manialab.sa/images/' + visitor.qr_code" alt="QrCode">

            <span class="name">{{ visitor.first_name + ' ' + visitor.last_name }}</span>
        </div>
        <i class="fas fa-download" @click="print"></i>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
import { useToast } from 'vue-toast-notification';

export default {
    data() {
        return {
            visitor: '',
            loading: true,
        }
    },
    methods: {
        print() {
            var HTML_Width = $(".html-content").width();
            var HTML_Height = $(".html-content").height();
            var top_left_margin = 0;
            var PDF_Width = HTML_Width;
            var PDF_Height = (HTML_Height);
            var canvas_image_width = HTML_Width;
            var canvas_image_height = HTML_Height;
            var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
            html2canvas($(".html-content")[0]).then(function (canvas) {
                var imgData = canvas.toDataURL("image/jpeg", 1.0);
                var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
                pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
                for (var i = 1; i <= totalPDFPages; i++) {
                    pdf.addPage(PDF_Width, PDF_Height);
                    pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
                }
                pdf.save('badge');
            });
        },
    },
    mounted() {
        const htm2canvascript = document.createElement("script");
        htm2canvascript.setAttribute("src", "/html2canvas.js?v=2");
        const jspdfscript = document.createElement("script");
        jspdfscript.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js");
        document.head.appendChild(htm2canvascript);

        this.axios.post(this.$store.state.baseURL + 'scan-visitor', {
            token_key: this.$route.query.t
        }).then(response => {
            if (!response.data.success) {
                useToast().error('Something Went Wrong');
            } else {
                this.visitor = response.data.data.visitor;
                this.loading = false;
            }
        }).then(() => {
            this.print()
        })
    }
}
</script>

<style scoped>
/* * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
} 

@media print {
    .noprint {
        visibility: hidden;
    }
}

@page {
    size: auto;
    margin: 0mm;
} */

.loading {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading img {
    width: 100px;
    animation: loading 1.5s infinite ease-in-out;
}

@keyframes loading {
    0% {
        scale: 1;
    }

    50% {
        scale: 1.3;
    }

    100% {
        scale: 1;
    }
}

.html-content {
    min-height: 90vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 500px;
}

.logo {
    width: 350px;
}

.qrcode {
    width: 350px;
}

.name {
    width: 300px;
    height: 50px;
    font-weight: bold;
    font-size: 1.1rem;
    background-color: #178285;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

i {
    cursor: pointer;
    background: #F7941D;
    color: #FFF;
    border-radius: 50%;
    font-size: 15px;
    padding: 15px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
}
</style>
<template>
    <Modal v-if="show_modal" :visitor="visitor" :checklog="checklog" @hide="openModal" @checkin="checkin"
        @checkout="checkout" @getGift="getGift"></Modal>

    <div class="qrcode-green">
        <qrcode-stream :camera="isMobile ? camera : ''" v-if="qr_mounted" @decode="scanVisitor"
            @init="isMobile ? onInit : null">
        </qrcode-stream>
        <span style="display: block; margin: auto;" v-else>
            Loading...
        </span>
    </div>
    
    <!-- Add input field here -->
    <input type="text" v-model="manualInput" @input="handleInput" placeholder="Enter code manually" ref="manualInputRef" />
</template>

<script>
import swal from "sweetalert";
import { QrcodeStream } from "vue3-qrcode-reader";
import Modal from "./Modal.vue";
import { useToast } from "vue-toast-notification";
import _ from "lodash"; // Import lodash for debounce

export default {
    data() {
        return {
            token_key: '',
            visitor: '',
            checklog: '',
            qr_mounted: true,
            show_modal: false,
            camera: 'rear',
            selt_input_scaner: null,
            noRearCamera: false,
            noFrontCamera: false,
            manualInput: '', // Add data property for manual input
        }
    },

    components: {
        QrcodeStream, Modal
    },

    computed: {
        isMobile() {
            return navigator.userAgent.match(/mobile/i) ? true : false;
        }
    },

    methods: {
        changecaminp_method(xx) {
            this.camera_or_input = xx;
            if (xx == false) {
                setTimeout(() => {
                    this.$refs.selt_input_scanerref.focus();
                    this.selt_input_scaner = null;
                }, 1000);
            }
        },

        openModal() {
            this.show_modal = !this.show_modal;
            this.qr_mounted = true;
        },

        switchCamera() {
            switch (this.camera) {
                case 'front':
                    this.camera = 'rear'
                    break
                case 'rear':
                    this.camera = 'front'
                    break
            }
        },

        async onInit(promise) {
            if (this.isMobile()) {
                try {
                    await promise
                } catch (error) {
                    const triedFrontCamera = this.camera === 'front'
                    const triedRearCamera = this.camera === 'rear'

                    const cameraMissingError = error.name === 'OverconstrainedError'

                    if (triedRearCamera && cameraMissingError) {
                        this.noRearCamera = true
                    }
                    if (triedFrontCamera && cameraMissingError) {
                        this.noFrontCamera = true
                    }
                }
            }
        },

        async scanVisitor(decodedString) {
            this.token_key = decodedString;
            this.qr_mounted = false;

            this.axios.post(this.$store.state.baseURL + 'scan-visitor',
                {
                    'token_key': this.token_key
                }).then(response => {
                    if (!response.data.success) {
                        return swal('Error!', response.data.msg, 'error').then(() => {
                            this.qr_mounted = true;
                        })
                    } else {
                        this.visitor = response.data.data.visitor;
                        this.checklog = response.data.data.checklog;
                        this.show_modal = true;
                    }
                });
        },

        async checkin() {
            this.axios.post(this.$store.state.baseURL + 'checkin',
                {
                    'token_key': this.token_key
                }).then(response => {
                    if (!response.data.success) {
                        useToast().error('Something went wrong');
                        this.show_modal = false;
                        this.qr_mounted = true;
                    } else {
                        useToast().success('تم تسجيل الدخول');
                        this.show_modal = false;
                        this.qr_mounted = true;
                    }
                });
        },

        async checkout() {
            this.axios.post(this.$store.state.baseURL + 'checkout',
                {
                    'token_key': this.token_key
                }).then(response => {
                    if (!response.data.success) {
                        useToast().error('Something went wrong');
                        this.show_modal = false;
                        this.qr_mounted = true;
                    } else {
                        useToast().success('تم تسجيل الخروج');
                        this.show_modal = false;
                        this.qr_mounted = true;
                    }
                });
        },

        getGift() {
            this.axios.post(this.$store.state.baseURL + 'gift',
                {
                    'token_key': this.token_key
                }).then(response => {
                    if (!response.data.success) {
                        useToast().error('Something went wrong');
                        this.show_modal = false;
                        this.qr_mounted = true;
                    } else {
                        useToast().success('تم الحصول على الهدية');
                    }
                });
        },

        handleInput: _.debounce(function () {
            if (this.manualInput.length > 10) {
                this.scanVisitor(this.manualInput);
                this.manualInput = '';
            }
        }, 1000) // 1 second debounce
    }
}
</script>

<style scoped>
.qrcode-green {
    position: relative;
    width: 350px;
    height: 350px;
    margin: 50px auto;
    background-color: #fff;
}

.qrcode-green::before {
    content: '';
    position: absolute;
    top: -20px;
    right: -20px;
    width: 200px;
    height: 200px;
    background-color: transparent;
    border: solid 10px #178285;
    border-bottom: none;
    border-left: none;
}

.qrcode-green::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: -20px;
    width: 200px;
    height: 200px;
    background-color: transparent;
    border: solid 10px #178285;
    border-top: none;
    border-right: none;
}

input {
    display: block;
    margin: 20px auto;
    padding: 10px;
    font-size: 16px;
    width: calc(100% - 40px);
    max-width: 350px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@media (max-width: 475px) {

    .qrcode-green {
        width: 90% !important;
        height: 275px !important;
    }

    .qrcode-green::before,
    .qrcode-green::after {
        width: 120px !important;
        height: 120px !important;
    }
}
</style>

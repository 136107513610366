<template>
    <div class="check-view" style="text-align: center;">
        <hr>
        <img class="logo" src="../assets/logo.png" alt="Logo">

        <Scanner></Scanner>
    </div>
</template>

<script>
import Scanner from '@/components/Scanner.vue';

export default {
    components: {
        Scanner
    },
    mounted() {
        $("head").append(
            "<script id='tail_css' src='https://cdn.tailwindcss.com'><\/script>"
        );
    }
}
</script>

<style scoped>
hr {
    height: 20px;
    margin: 0;
    background-color: #F7941D;
    border: none;
    outline: none;
}

.logo {
    width: 350px;
    margin: 40px auto;
}

/* .buttons button {
width: 100px;
height: 40px;
border: 1px solid #F7941D;
outline: none;
color: #FFF;
background: #F7941D;
margin: 0 10px;
cursor: pointer;
transition: all 0.3s ease;
}

.buttons button:hover {
color: #F7941D;
background: #FFF;
}

.buttons button.active {
color: #F7941D;
background: #FFF;
} */
</style>

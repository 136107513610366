import { createRouter, createWebHistory } from "vue-router";
import RegisterView from "@/views/RegisterView.vue";
import BadgeView from "@/views/BadgeView.vue";
import EvaluationView from "@/views/EvaluationView.vue";
import CheckView from "@/views/CheckView.vue";

const routes = [
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/badge",
    name: "badge",
    component: BadgeView,
  },
  {
    path: "/evaluation",
    name: "evaluation",
    component: EvaluationView,
  },
  {
    path: "/check",
    name: "check",
    component: CheckView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

<template>
    <div class="modal relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                    class="relative transform overflow-hidden bg-white text-right shadow-xl transition-all sm:my-8 w-full max-w-lg">
                    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div class="mt-3 sm:mx-4 sm:mt-0 text-right">
                            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                                معلومات عن الزائر
                            </h3>
                            <div>
                                <ul role="list" class="divide-y divide-gray-150">
                                    <li class="flex justify-between gap-x-6 py-5">
                                        <div class="flex gap-x-4">
                                            <div class="min-w-0 flex-auto">
                                                <p>
                                                    الاسم الكامل: {{ visitor.first_name + ' ' + visitor.last_name }}
                                                </p>
                                                <p>
                                                    البريد الالكتروني: {{ visitor.email }}
                                                </p>
                                                <p>
                                                    رقم الهاتف: {{ visitor.phone }}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="flex justify-between gap-x-6 py-5">
                                        <div class="flex gap-x-4">
                                            <div class="min-w-0 flex-auto">
                                                <p>
                                                    تسجيل الدخول <i class="ms-2"
                                                        :class="(checklog != null && checklog.checkin_at != null) ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i>
                                                </p>
                                                <p>
                                                    تسجيل الخروج <i class="ms-2"
                                                        :class="(checklog != null && checklog.checkout_at != null) ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i>
                                                </p>
                                                <p>
                                                    الحصول على الهدية <i class="ms-2"
                                                        :class="(checklog != null && checklog.gift) || got_gift ? 'fas fa-check-circle text-green-500' : 'fas fa-times-circle text-red-500'"
                                                        @click="getGift" style="cursor: pointer;"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="buttons bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button"
                            class="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-white sm:mt-0 sm:w-auto"
                            @click.self="hideModal" style="background-color: #F7941D;">حسنا</button>
                        <div>
                            <button type="button"
                                class="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-white sm:mt-0 sm:w-auto"
                                @click.self="checkin" style="background-color: #178285;">تسجيل الدخول</button>
                            <button type="button"
                                class="mt-3 ms-0 sm:ms-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold text-white sm:mt-0 sm:w-auto"
                                @click.self="checkout" style="background-color: #178285;">تسجيل الخروج</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['visitor', 'checklog'],
    data() {
        return {
            got_gift: false
        }
    },
    methods: {
        checkout() {
            this.$emit('checkout')
        },
        checkin() {
            this.$emit('checkin')
        },
        hideModal() {
            this.$emit('hide')
        },
        getGift() {
            this.got_gift = true;
            this.$emit('getGift')
        }
    },
    beforeUnmount() {
        this.$emit('scannerOn')
    }
}
</script>

<style scoped>
.modal {
    direction: rtl;
}

.buttons {
    justify-content: space-between;
}
</style>

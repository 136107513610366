<template>
    <div class="evaluation-view">
        <hr>
        <img class="logo" src="../assets/logo.png" alt="Logo">
        <div v-for="question in questions" :key="question.id">
            <div class="question">
                <span>{{ question.order }}</span> {{ question.question }}
            </div>
            <div class="answers">
                <div class="answer" v-for="(ans, index) in rates" :key="index"
                    :class="['face-fill-' + question.id + '-' + index, 'face-fill-' + question.id]"
                    @click="selectRate(question.id, ans.value, index)">
                    <img :src="'https://hr-registration.manialab.sa/images/face' + ans.rate + '.svg'">
                    <center>{{ ans.value }}</center>
                </div>
            </div>
        </div>
        <button @click="submit">ارسال</button>
    </div>
</template>

<script>
import { useToast } from 'vue-toast-notification';

export default {
    data() {
        return {
            rates: [
                { rate: 1, value: 'سيء جدا' },
                { rate: 2, value: 'حسن' },
                { rate: 3, value: 'سعيد جدا' },
            ],
            answers: [],
            questions: []
        }
    },
    mounted() {
        this.axios.get(this.$store.state.baseURL + 'get-questions')
            .then(response => {
                if (!response.data.success) {
                    useToast().error('Something Went Wrong');
                } else {
                    this.questions = response.data.data
                }
            })
    },
    methods: {
        selectRate(question, answer, index) {
            var questionIndex = this.answers.findIndex((item) =>
                item.question_id == question
            );

            if (questionIndex == -1) {
                this.answers.push({
                    "question_id": question,
                    "answer": answer,
                })
            } else {
                this.answers[questionIndex] = {
                    "question_id": question,
                    "answer": answer,
                }
            }

            $('.face-fill-' + question).removeClass('active');
            $('.face-fill-' + question + '-' + index).addClass('active');
        },

        submit() {
            this.axios.post(this.$store.state.baseURL + 'submit-answer', {
                'answers': this.answers
            }).then(response => {
                if (!response.data.success) {
                    useToast().error('Something Went Wrong');
                } else {
                    useToast().success('تم ارسال اجوبتك');
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                }
            })
        }
    }
}
</script>

<style scoped>
.evaluation-view {
    text-align: center;
    direction: rtl;
}

hr {
    height: 20px;
    margin: 0;
    background-color: #F7941D;
    border: none;
    outline: none;
}

.logo {
    width: 350px;
    margin: 50px 0 40px;
}

.question {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.question span {
    color: #178285;
    font-weight: bold;
    font-size: 34px;
    margin-left: 10px;
}

.answers {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.answers .answer {
    position: relative;
    cursor: pointer;
}

.answers .answer img {
    width: 60px;
    margin: 25px;
}

.answers .answer center {
    text-align: center;
    color: #FFF;
    font-weight: bold;
}

.answers .active::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background: #F7941D70;
    z-index: -1;
}

button {
    margin: 20px 0;
    width: 200px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #178285;
    outline: none;
    background-color: #178285;
    color: #FFF;
    transition: all 0.3s ease;
    font-size: 16px;
}

button:hover {
    background-color: #FFF;
    color: #178285;
}
</style>